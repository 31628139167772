import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import styles from "./article.module.css"
import Layout from "../components/layout"
import authorPhoto from "../images/Frame.png"
import DiagonalArrowIcon from "../components/svg/diagonalArrowIcon"
import SmallArrowIcon from "../components/svg/smallArrowIcon"
import AuthorBlock from "../components/authorBlock"
import SEO from "../components/seo"
import NewsletterSubscribe from "../components/newsletterSubscribe"
import { API_URL, getData } from "../../dataFetcher"
import ArticlePreview from "../components/articlePreview"

const CustomLinkComponent = ({ href, children }) => {
  return (
    <Link to={href}>
      {children[0]}
      <SmallArrowIcon className={styles.linkArrow} />
    </Link>
  )
}

const CustomCodeComponent = ({ children }) => {
  return (
    <ReactMarkdown
      source={children}
      className={styles.quote}
      renderers={{ link: CustomLinkComponent }}
    />
  )
}

const CustomImageComponent = ({ src, alt }) => {
  return <img src={src} alt={alt} />
  // return <img src={`${API_URL}${src}`} alt={alt} />
}

export default function Article({ pageContext: { article } }) {
  const renderers = {
    inlineCode: CustomCodeComponent,
    link: CustomLinkComponent,
    image: CustomImageComponent,
  }
  const [relatedArticleInfo, setRelatedArticleInfo] = useState({})
  useEffect(() => {
    getData(`${API_URL}/articles/${article.relatedArticle}`)
      .catch(error => {
        console.error(error)
      })
      .then(result => {
        console.log("result:", result)
        setRelatedArticleInfo(result)
      })
  }, [article.relatedArticle])

  const articlePublishDate = new Date(article.publishDate)
  const articlePublishMonthString = articlePublishDate
    .toLocaleString("en-US", { month: "long" })
    .toLowerCase()

  const articlePublishDateString = `${articlePublishDate.getDate()} ${articlePublishMonthString} ${articlePublishDate.getFullYear()}`

  return (
    <Layout
      mainClassName={styles.wrapper}
      pageWrapperClassName={styles.fullPageWrapper}
    >
      <SEO title={`story: ${article.title}`} />
      <div className={styles.articleInformation}>
        <div className={styles.topArticleInformation}>
          <div className={styles.backLink}>
            <Link to="/stories/" className={styles.backToStories}>
              <DiagonalArrowIcon className={styles.arrow} />
              <p>stories</p>
            </Link>
            <span className={styles.articlePublishingDate}>
              {articlePublishDateString}
            </span>
          </div>
        </div>
        <h1 className={styles.header}>{article.title}</h1>
        {article.description && (
          <h4 className={styles.articleDescription}>{article.description}</h4>
        )}
        {article.user && (
          <AuthorBlock
            className={styles.authorBlock}
            authorPhotoLink={authorPhoto}
            name={article.user.username}
            email={article.user.email}
            status="founder"
          />
        )}
      </div>
      <img
        src={article.coverImage}
        alt={article.title}
        className={styles.articleMainImage}
      />
      {/* <img
      srcSet={`${API_URL}${article.coverImage.formats.thumbnail.url} 245w, ${API_URL}${article.coverImage.formats.small.url} 500w, ${API_URL}${article.coverImage.formats.medium.url} 750w, ${API_URL}${article.coverImage.formats.large.url} 1000w`}
      src={`${API_URL}${article.coverImage.url}`}
      alt={article.coverImage.name}
      className={styles.articleMainImage}
    /> */}
      <ReactMarkdown
        source={article.content}
        className={styles.articleBody}
        renderers={renderers}
      />
      <div className={`${styles.articleInformation} ${styles.bottom}`}>
        {article.user && (
          <AuthorBlock
            className={styles.authorBlock}
            authorPhotoLink={authorPhoto}
            name={article.user.username}
            email={article.user.email}
            status="founder"
          />
        )}
        <hr />
        <NewsletterSubscribe className={styles.newsletterSubscribe} />
        {relatedArticleInfo && relatedArticleInfo.title && (
          <ArticlePreview
            className={styles.relatedArticle}
            articleLink={`/stories/${article.relatedArticle}`}
            imgLink={relatedArticleInfo.coverImage}
            header={relatedArticleInfo.title}
            articleInformationClassName={styles.relatedArticleInformation}
          />
        )}
      </div>
    </Layout>
  )
}
