import { Link } from "gatsby"
import React from "react"
import styles from "./authorBlock.module.css"
import PropTypes from "prop-types"

export default function AuthorBlock({
  className,
  authorPhotoLink,
  name,
  status,
  email,
}) {
  return (
    <div className={`${styles.authorBlock} ${className ? className : ""}`}>
      <img src={authorPhotoLink} alt="avatar" className={styles.authorPhoto} />
      <div className={styles.authorInformation}>
        <div className={styles.mainInformation}>
          <Link to="/" className={styles.authorName}>
            {name}
          </Link>
          <span className={styles.separator}> / </span>
          <span className={styles.authorStatus}>{status}</span>
        </div>
        <div className={styles.authorEmail}>{email}</div>
      </div>
    </div>
  )
}

AuthorBlock.propTypes = {
  className: PropTypes.string,
  authorPhotoLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

AuthorBlock.defaultProps = {
  className: "",
}
